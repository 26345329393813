body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #eee;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#board {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 500px;
  height: 750px;
  background: white;
  border: 1px solid silver;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
}

#toolbar {
  position: absolute;
  left: 10px;
  top: 10px;
  display: inline-flex;
  border: 1px solid silver;
  background: #eee;
  border-radius: 5px;
  padding: 4px 4px;
  box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
}

#mouseOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: none;
}

#boardCanvas{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.btn-tool  {
  height: 28px;
  width: 28px;
  margin-right: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

.btn-tool:hover {
  background-color: white;
}

.btn-selected {
  box-shadow: 0px 0px 0px 2px steelblue inset;
}

.btn-separator {
  width: 16px;
}

.paint-cursor {
  visibility: hidden;
  position:absolute;
  border-radius: 50%;
  cursor: none;
  pointer-events: none;
}

.color-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
